import React from 'react';
import SEO from "../components/seo";

import { 
  Wrapper,
  Smart,
  Text, 
  Button 
} from '../styles/styles-404';

import GlobalStyles from '../styles/GlobalStyles';

const Page404 = () => {
  return (
    <>
      <SEO 
        title="404: Página não encontrada"
      />
      <Wrapper>
        <Smart />
        <Text>Ops... Essa página não existe!</Text>
        <Button cover bg="#fff" to="/">Voltar para o site!</Button>
      </Wrapper>
      <GlobalStyles />
    </>
  );
}

export default Page404;