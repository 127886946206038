import styled from 'styled-components';
import AniLink from "gatsby-plugin-transition-link/AniLink";

import SmartEnvios from '../assets/images/smart-logo.svg';

export const Wrapper = styled.section`
  color: #fff;
  width: 100%;
  height: 100vh;
  padding: 3rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Smart = styled(SmartEnvios)`
  width: 20rem;
  margin-bottom: 2rem;
`;

export const Text = styled.h1`
  font-size: 2.5rem;
  color: #044D75;
  margin-bottom: 2rem;
`;

export const Button = styled(AniLink)`
  text-decoration: none;
  font-family: 'Sora', sans-serif;
  font-weight: bold;
  width: 150px;
  height: 40px;
  color: #fff;
  text-align: center;
  padding: 13px 0;
  border-radius: 4px;
  background: #f58634;

  &:hover {
    background: #489be8;
  }
`;
